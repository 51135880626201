.react-checkbox-tree {
  flex: 1;
  overflow-y: auto;
  border: 1px solid #2fc5d3;
  border-radius: 10px;
}

.react-checkbox-tree ol {
  overflow-x: hidden;
}

.rct-text {
  display: flex;
  padding: 4px 0;
}

.rct-collapse, .rct-checkbox, .rct-node-icon {
  display: flex;
}

.rct-collapse *, .rct-checkbox *, .rct-node-icon * {
  width: auto;
}

.react-checkbox-tree label {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  overflow: hidden;
}

.react-checkbox-tree label .rct-node-icon {
  order: 1;
}

.rct-text:hover {
  background: rgba(0,0,0, 0.04);
}

.rct-text:active {
  background: rgba(0,0,0, 0.08);
}

.rct-text:hover > label:hover {
  background: transparent;
}

.rct-text:active > label:active {
  background: transparent;
}

.rct-node-parent:hover.rct-node-expanded:hover {
  background: transparent;
}

.react-checkbox-tree label:hover {
  background: inherit;
}

.react-checkbox-tree label:active {
  background: inherit;
}

.rct-checkbox {
  margin: 0 8px;
  padding: 4px;
  border-radius: 50%;
}

.rct-collapse {
  margin: 0 8px;
  padding: 4px;
  width: 40px;
  border-radius: 50%;
}

.rct-collapse-btn {
  display: flex;
  align-items: center;
  align-self: center;
  width: 40px;
  height: 40px;
  margin-right: 0;
}

.rct-collapse:hover.rct-collapse-btn {
  background: rgba(0,0,0, 0.04);
}

.rct-node-icon {
  padding: 0;
}

.rct-node-icon .MuiSvgIcon-root {
  margin-right: 8px;
}

.rct-title {
  padding: 0;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
